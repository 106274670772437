import * as React from "react";
import constants from "constants/index";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import SectionWithRightImage from "../../components/pages/solutions/SectionWithRightImage";
import SectionWithRightVideoPrototype from "../../components/pages/solutions/SectionWithRightVideoPrototype";
import HeroBigTextBannerWithVideo from "../../components/pages/solutions/HeroBigTextBannerWithVideo";
import SectionWithLeftImage from "../../components/pages/solutions/SectionWithLeftImage";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import HomeExamples from "../../components/pages/home/HomeExamples";
import Shoutout from "../../components/pages/signup/Shoutout";
import FAQSection from "../../components/pages/faq/FAQSection";
import BlogHighlight from "../../components/pages/blog/BlogHighlight";
import Content from "../../components/layout/Content/Content";

import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/Design-prototyping-An-introduction.png";
import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/How-to-prototype-an-app-for-market.png";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/How-to-go-from-Wireframe-to-Low-fidelity-Prototype.png";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Home = ({ data }) => (
  <Layout>
    <Meta
      title="UI Prototyping Tool | UX/UI Prototyping Made Easy | Uizard"
      description="UI prototype Design for apps, websites, and digital products with Uizard, the AI-powered prototyping software. Product prototyping made easy."
      url="/prototyping/"
      faqschema='{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What are UI prototyping tools?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Prototyping tools enables users to build interactive models of web pages or applications by weaving together visual assets, editorial copy, navigational elements, and other components to create a visualization of a website or app. Key to good UX/UI prototyping tools is the ability to demonstrate how various elements, CTAs, and pages link together, thereby demonstrating how a final design should both look and function."
            }
          },
          {
            "@type": "Question",
            "name": "How do I use UI prototyping tools?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Designed for ease of use, UI prototype tools tend to be fairly straight forward, requiring only sign up before a user can begin working on their project. A lot of UX/UI prototyping tools come with some form of onboarding material to guide users however, more user-friendly prototyping tools (such as Uizard) include pre-made templates and components that can easily be dragged and dropped to suit each user or individual project."
            }
          },
          {
            "@type": "Question",
            "name": "What is the best UI prototyping tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "When it comes to what is the best UI prototyping tool, this largely depends on the level of design experience of the user. For users that have little to no design experience, a tool made for non-designers (such as Uizard) is likely more appropriate. For more advanced designers, tools like Figma are a common choice."
            }
          },
          {
            "@type": "Question",
            "name": "Are there any free UI prototyping tools?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Many of the leading UI prototyping tools out there come with some form of free plan which allows users to test and try the software before advancing onto a paid plan. With tools such as Uizard, free plans come with more than enough features to help users build UX/UI prototypes for their products or projects."
            }
          }
        ]
      }'
    />

    <HeroBigTextBannerWithVideo
      tagline="UI Prototyping Tool"
      headline="Design prototypes rapidly"
      description="Rapid app, web, and UI protoypting with our drag-and-drop editor."
      imageA={data.protoCoverBig.childImageSharp}
      imageB={data.protoCoverSmall.childImageSharp}
      imageAlt="a user designing a screen in uizard for a mobile app"
      link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
      cta="Sign up for free"
      tagAsTitle={true}
      videoName="uizard-prototype-cover"
    />

    <SectionWithLeftImage
      headline="From idea to prototype"
      description="Uizard revolutionizes UX/UI prototyping like never before, helping you bring your ideas to life as quickly as possible. Uizard's <a:https://uizard.io/design-assistant/>AI Design Assistant</a> can transform your digital product design ideas into fully functioning UI prototypes, rapidly."
      imageA={data.protoIdeaToPrototypeBig.childImageSharp}
      imageB={data.protoIdeaToPrototypeSmall.childImageSharp}
      imageAlt="how a user can use Uizard to go from a rough wireframe to a design mockup and a polished design"
      link="/design-assistant/"
    />

    <SectionWithRightVideoPrototype
      headline="Test user journeys in seconds"
      description="Populate your <a:https://uizard.io/ux-design/>UX design</a> with assets, add dynamic links between screens, preview your web or app prototype in real-time, and share your prototype with colleagues and team members... interactive UX/UI prototyping made simple with Uizard."
      imageA={data.protoUserJourneyBig.childImageSharp}
      imageB={data.protoUserJourneySmall.childImageSharp}
      imageAlt="Visual illustrating how a user can test their interactive prototypes in Uizard"
      videoName="uizard-user-journey"
    />

    <SectionWithRightImage
      headline="Collaborate, ideate, and prototype"
      description="Working with a team? Need sign-off from key stakeholders? We understand that bringing your app idea to life in full UI prototype format is often a collaborative effort. With our smart sharing, you can collaborate in real-time with the click of a button."
      imageA={data.protoCollaborateBig.childImageSharp}
      imageB={data.protoCollaborateSmall.childImageSharp}
      imageAlt="Visual highlighting the collaborative capabilities of Uizard"
    />

    <HomeExamples />
    <HomeFeatured />

    <BlogHighlight
      title="Learn UX/UI prototyping"
      blogs={[
        {
          image: blogThumbnail1,
          link: "https://uizard.io/blog/design-prototyping-an-introduction/",
          alt: "Thumbnail for blog titled Design prototyping: An introduction",
        },
        {
          image: blogThumbnail2,
          link: "https://uizard.io/blog/how-to-prototype-an-app-for-market/",
          alt: "Thumbnail for blog titled How to prototype an app for market…",
        },
        {
          image: blogThumbnail3,
          link: "https://uizard.io/blog/how-to-go-from-wireframe-to-low-fidelity-prototype/",
          alt: "Thumbnail for blog titled How to go from Wireframe to Low-fidelity Prototype",
        },
      ]}
    />

    <FAQSection
      title={"Prototyping FAQs"}
      questions={[
        {
          question: "What are UI prototyping tools?",
          answer:
            "Prototyping tools enables users to build interactive models of web pages or applications by weaving together visual assets, editorial copy, navigational elements, and other components to create a visualization of a website or app. Key to good UX/UI prototyping tools is the ability to demonstrate how various elements, CTAs, and pages link together, thereby demonstrating how a final design should both look and function.",
        },
        {
          question: "How do I use UI prototyping tools?",
          answer:
            "Designed for ease of use, UI prototype tools tend to be fairly straight forward, requiring only sign up before a user can begin working on their project. A lot of UX/UI prototyping tools come with some form of onboarding material to guide users however, more user-friendly prototyping tools (such as Uizard) include pre-made templates and components that can easily be dragged and dropped to suit each user or individual project.",
        },
        {
          question: "What is the best UI prototyping tool?",
          answer:
            "When it comes to what is the best UI prototyping tool, this largely depends on the level of design experience of the user. For users that have little to no design experience, a tool made for non-designers (such as Uizard) is likely more appropriate. For more advanced designers, tools like Figma are a common choice.",
        },
        {
          question: "Are there any free UI prototyping tools?",
          answer:
            "Many of the leading UI prototyping tools out there come with some form of free plan which allows users to test and try the software before advancing onto a paid plan. With tools such as Uizard, free plans come with more than enough features to help users build UX/UI prototypes for their products or projects.",
        },
      ]}
    />

    <ContentExtended>
      <Shoutout
        headline="UI prototyping made easy"
        description="Bring your UX/UI prototypes to life in minutes"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    protoCoverBig: file(
      relativePath: { eq: "prototyping/Proto_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 976, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoCoverSmall: file(
      relativePath: { eq: "prototyping/Proto_Cover_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 488, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoIdeaToPrototypeBig: file(
      relativePath: { eq: "prototyping/Proto_IdeaToPrototype_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoIdeaToPrototypeSmall: file(
      relativePath: { eq: "prototyping/Proto_IdeaToPrototype_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoUserJourneyBig: file(
      relativePath: { eq: "prototyping/Proto_UserJourney_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoUserJourneySmall: file(
      relativePath: { eq: "prototyping/Proto_UserJourney_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoCollaborateBig: file(
      relativePath: { eq: "prototyping/Proto_Collaborate2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1036, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoCollaborateSmall: file(
      relativePath: { eq: "prototyping/Proto_Collaborate1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 518, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
